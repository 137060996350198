<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">协议审批管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">协议财务复核列表</a>
                </span>
            </div>
            <!-- 条件查询区域和列表区域 -->
            <div class="framePage-body">
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <!-- 搜索区域 -->
                    <div class="searchbox" style="margin-bottom: 15px">
                        <div title="协议类型" class="searchboxItem">
                            <span class="itemLabel">协议类型:</span>
                            <el-select clearable placeholder="请选择协议类型" size="small" v-model="searchForm.protocolType">
                                <el-option v-for="item in protocolTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="签订机构" class="searchboxItem">
                            <span class="itemLabel">签订机构:</span>
                            <el-select size="small" v-model="searchForm.signCompId" remote :remote-method="getCompanyList"
                                filterable clearable placeholder="请至少输入两个字搜索">
                                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                                    :value="item.compId"></el-option>
                            </el-select>
                        </div>
                        <div title="经办人" class="searchboxItem">
                            <span class="itemLabel">经办人:</span>
                            <el-select clearable placeholder="请选择经办人" size="small" v-model="searchForm.createId">
                                <el-option v-for="item in operatorList" :key="item.userId" :label="item.fullname"
                                    :value="item.userId">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="报备日期" class="searchboxItem">
                            <span class="itemLabel">报备日期:</span>
                            <el-date-picker v-model="searchForm.filingDate" value-format="yyyy-MM-dd" type="date"
                                size="small" placeholder="请选择报备日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="searchbox">
                        <div title="行政区划" class="searchboxItem ci-full">
                            <span class="itemLabel">行政区划:</span>
                            <el-cascader clearable filterable v-model="searchForm.areaId" :options="areatreeList"
                                :props="propsarea" size="small"></el-cascader>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(true)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="financialExport">导出</el-button>
                        </div>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="listData" :height="tableHeight" size="small"
                            tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
                            <el-table-column label="协议类型" align="left" show-overflow-tooltip min-width="80">
                                <template v-slot="{ row }">
                                    {{ $setDictionary("PROTOCOL_TYPE", row.protocolType) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath"
                                min-width="220px" />
                            <el-table-column min-width="150" label="签订机构" align="left" prop="compName"
                                show-overflow-tooltip />
                            <el-table-column min-width="100" label="签订日期" align="left" prop="signDate"
                                show-overflow-tooltip />
                            <el-table-column min-width="160" label="有效期" align="left" prop="startDate"
                                show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ row.startDate }} - {{ row.endDate }}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100" label="结算标准" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ $setDictionary("SETTLEMENT_STANDARD_PAYMENT", row.settleStandardType) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="对接业务员" align="left" prop="salesman" show-overflow-tooltip />
                            <el-table-column min-width="90" label="审批状态" align="left" prop="processValue"
                                show-overflow-tooltip />
                            <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" min-width="150" />
                            <el-table-column min-width="90" label="报备日期" align="left" prop="filingDate"
                                show-overflow-tooltip />
                            <el-table-column label="财务备注" align="left" show-overflow-tooltip prop="financialRemark"
                                min-width="150" />
                            <el-table-column label="操作" align="left" prop="department" show-overflow-tooltip width="170"
                                fixed="right">
                                <template v-slot="{ row }">
                                    <el-button v-if="row.auditNode === '15'" type="text" size="mini" style="padding: 0 5px;"
                                        @click="financialReview(row)">
                                        财务复核
                                    </el-button>
                                    <el-button v-else type="text" size="mini" style="padding: 0 5px;"
                                        @click="seeDetail(row)">
                                        查看详情
                                    </el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="toVoid(row)"
                                        :disabled="row.auditNode === '100'">
                                        作废
                                    </el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange" />
            </div>
        </div>
    </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
    name: "agreementReview",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    created() {
        // 处理协议类型字典数据
        const protocolType = this.$setDictionary("PROTOCOL_TYPE", "list");
        Object.keys(protocolType).forEach((key) => {
            this.protocolTypeOptions.push({
                label: protocolType[key],
                value: key
            });
        });
        this.getListData();
        this.getUserInfo();
        this.getareatree();
    },
    data() {
        return {
            // 协议列表数据
            listData: [],
            // 查询条件表单
            searchForm: {
                protocolType: "", //协议类型
                compName: "",// 签订机构
                createId: "",// 经办人
                filingDate: "",// 报备日期
                areaId:"", // 行政区划
            },
            // 分页器数据
            pagination: {
                total: 0,
                size: 10,
                current: 1
            },
            // 协议类型
            protocolTypeOptions: [],
            // 签订机构数据
            companyList: [],
            // 经办人数据
            operatorList: [],
            // 审批状态
            approvalStatus: [],
            // 行政区划
            areatreeList: [], 
            // 行政区划 - 数据格式
            propsarea: {
                value: "value",
                label: "label",
                emitPath: false,
                checkStrictly: true,
            },
        }
    },
    computed: {
        ...mapGetters({ downloadItems: "getDownloadItems" }),
    },
    methods: {
        // 获取行政区划
        getareatree() {
            this.$post("/sys/area/tree").then((ret) => {
                this.areatreeList = ret.data;
            });
        },
        // 导出
        financialExport() {
            const { searchForm } = this;
            // 定义导出条件
            const condition = {};
            Object.keys(searchForm).forEach((key) => {
                if (searchForm[key]) condition[key] = searchForm[key];
            });
            this.$post("/biz/protocol/export", condition).then((res) => {
                const arr = [...res.data];
                for (let item of arr) {
                    if (!this.downloadItems.includes(item.taskId)) {
                        this.$store.dispatch("pushDownloadItems", item.taskId);
                    } else {
                        this.$message.warning(`[${item.fileName}]已经申请下载`);
                    }
                }
            }).catch((e) => {
                console.log(e);
            });
        },
        // 获取协议列表
        getListData(isQuery) {
            const { searchForm, pagination } = this;
            if (isQuery) pagination.current = 1;
            // 定义查询条件
            const condition = {};
            // 合并分页器数据
            condition["pageNum"] = pagination.current;
            condition["pageSize"] = pagination.size;
            // 合并查询数据
            Object.keys(searchForm).forEach((key) => {
                if (searchForm[key]) condition[key] = searchForm[key];
            });
            this.$post("/biz/protocol/pageList", condition).then(res => {
                this.listData = res.data.list;
                pagination.total = res.data.total;
            }).catch(e => {
                return e;
            });
        },
        // 分页器当前页改变
        currentChange(val) {
            const { pagination } = this;
            pagination.current = val;
            this.getListData();
        },
        // 分页器条数改变
        sizeChange(val) {
            const { pagination } = this;
            pagination.size = val;
            this.getListData();
        },
        // 获取签订机构数据
        getCompanyList(query) {
            if (query.trim().length >= 2) {
                this.$post("/sys/company/queryCompanyList", {
                    compName: query.trim()
                }).then((res) => {
                    this.companyList = res.data || [];
                }).catch(() => {
                    return;
                });
            } else {
                this.companyList = [];
            }
        },
        // 获取经办人数据
        async getUserInfo() {
            try {
                const res = await this.$post("/biz/audit/auditUser/getUserInfo", {
                    userRole: "10"
                });
                this.operatorList = res.data;
            } catch (e) {
                return e;
            }
        },
        // 财务复核
        financialReview(row) {
            const { protocolId } = row;
            this.$router.push({
                path: "/web/agreementAdd",
                query: { protocolId, review: true }
            });
        },
        // 作废协议
        toVoid(row) {
            const { protocolId } = row;
            try {
                this.$confirm('是否作废该协议？协议一经作废不可修改！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    try {
                        await this.$post("/biz/protocol/audit/invalid", { protocolId });
                        this.$message.success("操作成功");
                        this.getListData();
                    } catch (e) {
                        console.log(e);
                    }
                });
            } catch (e) {
                console.log(e);
            }
        },
        // 查看详情
        seeDetail(row) {
            const { protocolId } = row;
            this.$router.push({
                path: "/web/agreementAdd",
                query: {
                    protocolId,
                    afterReview: true // 判断是否是财务复核后的协议
                }
            });
        },
        getTableHeight(opDom = false, page = true) {
            let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
            if (opDom) tHeight -= 40 + 0.675 * 16 + 1;
            if (page) tHeight -= 32;
            this.tableHeight = tHeight;
        },
    },
    watch: {
        $route(to) {
            if (to.name === "agreementReview") {
                this.getListData();
            }
        }
    }
}
</script>

<style lang="less">
.searchbox {
    .searchboxItem {
        .el-input {
            input {
                width: 100%;
            }
        }
    }
}

.el-message-box {
    .el-message-box__btns {
        .el-button--primary {
            background-color: #5C6BE8;
            border: none;

            &:hover {
                background-color: #3d4fee;
            }
        }
    }
}

.el-table .el-table__fixed-right::before,
.el-table__fixed::before {
    height: 0 !important;
}
</style>